.container {
    width: 100%;
    min-height: 60vh;
    padding: 5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #09131B;
  border-radius: 10px;
  z-index: 10;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #8DF1C9;
  position: relative;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #09131B;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 2rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #09131B;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #09131B;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.contact-form .input-container label {
    position: absolute;
    left: 8%;
    padding: 1px 5px;
    font-size: 12px;
    margin: 10px 0;
    color: #09131B;
    background-color: #8DF1C9;
    transition: 1s;
    transform: translateY(-20px);
}
  
.contact-form .input-container span {
  position:absolute;
  left: 20px;
  bottom: -18px;
  font-size: 12px;
  color: rgb(211, 104, 104);
}

.check-icon {
  color: seagreen;
}

#submit-msg {
  color: rgb(211, 104, 104);
}

/* Remove chrome autofill color from inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #FFF7AD;
}

.text {
  color: #909598;
  margin: 3rem 0 5rem 0;
}

.information {
  display: flex;
  color:#8DF1C9;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  margin-right: 1rem;
}

.social-media {
  padding: 5rem 0 0 0;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons img {
  width: 35px;
  height: 35px;
  border: 2px solid #8DF1C9;
  border-radius: 25px;
  background-color: #09131B;
  color: #8DF1C9;
  margin-right: 2rem;
  transition: 0.3s;
}
.social-icons a:hover {
  transform: scale(1.15);
}

@media (max-width: 1080px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }
  
  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 640px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons img {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }
}